import Body from './Body';
import BodyRichText from './BodyRichText';
import FoodList from './FoodList';
import Hero from './Hero';
import Image from './Image';
import KeyInfo from './KeyInfo';
import StaticMap from './StaticMap';
import Title from './Title';
import WineList from './WineList';

export {
  Body,
  BodyRichText,
  FoodList,
  Hero,
  Image,
  KeyInfo,
  StaticMap,
  Title,
  WineList,
};
