import { device } from '../../../constants';
import styled from '@emotion/styled';
import React from 'react';

const ReviewBody = styled.div`
  white-space: pre-wrap;
`;

const Headline = styled.p`
  max-width: 335px;
  font-size: 24px;

  margin-top: 20px;
  margin-bottom: 22px;

  @media ${device.tablet} {
    margin-right: auto;
    max-width: 500px;

    margin-top: 0px;
    margin-bottom: 39px;

    font-size: 28px;
    text-align: left;
  }

  @media ${device.laptopL} {
    margin-bottom: 24px;

    font-size: 38px;
    max-width: 600px;
  }
`;

const Body = styled.p`
  max-width: 335px;

  margin-top: 10px;

  font-size: 18px;

  @media ${device.tablet} {
    max-width: 661px;
  }
`;

export default ({ body, headline }) => (
  <ReviewBody>
    <Headline className="SubHeading2">{headline}</Headline>
    <Body className="Body1">{body}</Body>
  </ReviewBody>
);
