import React from 'react';
import styled from '@emotion/styled';
import { withPrefix } from 'gatsby';

const speechBubbleURL = withPrefix('/speechBubble.svg');

const SpeechBubble = styled.div`
  width: 216px;
  height: 163px;
  margin-left: 180px;
  margin-bottom: -5px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background: url(${speechBubbleURL});
  background-size: 216px 163px;
`;

const Content = styled.div`
  max-width: 146px;
  max-height: 134px;

  p {
    margin: 2px;
  }
`;

export default ({ streetAddress, title }) => (
  <SpeechBubble>
    <Content>
      <p className="Subtext1-Bold">{title}</p>
      <p className="Subtext1">{streetAddress}</p>
    </Content>
  </SpeechBubble>
);
