import _ from 'lodash';
import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../../constants';

const getPrice = price => '£'.repeat(price);

const getCategories = categories =>
  _.map(categories, _.upperFirst)
    .slice(0, 3)
    .join(' / ');

const Link = styled.a`
  :link,
  :visited {
    color: ${color.grey7};
  }
`;

const getWebsite = website => (
  <Link href={website} target="_blank">
    {website}
  </Link>
);

const getPhoneNumber = phoneNumber => (
  <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
);

export const getList = ({
  price,
  location,
  categories,
  website,
  phoneNumber,
}) => [
  ['Price', getPrice(price)],
  ['Neighbourhood', location],
  ['Good for', getCategories(categories)],
  ['Website', getWebsite(website)],
  ['Phone', getPhoneNumber(phoneNumber)],
];
