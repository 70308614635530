import React from 'react';
import styled from '@emotion/styled';
import { size } from '../../../constants';

const { mobileS, mobileM, mobileL, tablet, laptopL } = size;

const Image = styled.img`
  width: 100%;
  height: 100%;

  max-width: 100%;
  max-height: 100%;
`;

export default ({ image }) => {
  const imageAndFit = `${image}?fit=fill`;

  const defaultUrl = `${imageAndFit}&w=${laptopL}&h=928`;

  const srcSet = `
  ${imageAndFit}&w=${mobileS}&h=240 ${mobileS}w,
  ${imageAndFit}&w=${mobileM}&h=240 ${mobileM}w,
  ${imageAndFit}&w=${mobileL}&h=240 ${mobileL}w,
  ${imageAndFit}&w=${tablet}&h=493 ${tablet}w,
  ${imageAndFit}&w=${laptopL}&h=928 ${laptopL}w,
  `;

  const sizes = `
  (max-width: ${mobileS}px) ${mobileS}px,
  (max-width: ${mobileM}px) ${mobileM}px,
  (max-width: ${mobileL}px) ${mobileL}px,
  (max-width: ${tablet}px) ${tablet}px,
  ${laptopL}px,
  `;

  return <Image src={defaultUrl} srcSet={srcSet} sizes={sizes} alt="Hero" />;
};
