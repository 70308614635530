import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import { Title, Hero, BodyRichText } from '../components/Review';
import { color, device } from '../constants';

const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  padding-top: 20px;

  @media ${device.tablet} {
    padding-top: 50px;
  }

  @media ${device.laptopL} {
    padding-top: 80px;
  }
`;

export default props => {
  const { citiesList, defaultCity, token, style, ...post } = props.pageContext;
  const { author, bodyRichText, headline, hero, title, publishDate } = post;

  return (
    <Layout backgroundColor={color.cream2}>
      <Head title={title} description={headline} />
      <Header
        citiesList={citiesList}
        defaultCity={defaultCity}
        backgroundColor={color.white}
        textColor={color.black}
        hamburgerIconDark={true}
        sticky={true}
      />
      <Title title={title} author={author} date={publishDate} />
      <Hero image={hero.file.url} />
      <Content>
        <BodyRichText bodyRichText={bodyRichText} headline={headline} />
      </Content>
    </Layout>
  );
};
