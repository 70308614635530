import React from 'react';
import { getList } from './helpers';
import { Button, Rating, Container, List, ListItem } from './components';

export default class KeyInfo extends React.Component {
  list = getList(this.props);
  state = { expanded: false };

  toggle = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const { rating } = this.props;
    const { expanded } = this.state;

    return (
      <Container>
        <Button expanded={expanded} onClick={this.toggle} />
        <Rating rating={rating} />
        <List expanded={expanded}>
          {this.list.map(([title, value]) => (
            <ListItem key={title}>
              <p className="Body3-Bold">{title} &nbsp;</p>
              <span className="Body3">{value}</span>
            </ListItem>
          ))}
        </List>
      </Container>
    );
  }
}
