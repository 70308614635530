import React from 'react';
import styled from '@emotion/styled';
import { device } from '../../../constants';

const Wine = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 335px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;

    min-width: 663px;
  }
`;

const Headings = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  font-size: 15px;

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

const Title = styled.p`
  margin-bottom: 0px;
`;

const Origin = styled.p`
  margin-top: 3px;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: 15px;

  white-space: pre-wrap;

  @media ${device.tablet} {
    max-width: 374px;
  }
`;

const getVintage = vintage => (vintage === 0 ? 'NV' : vintage);

export default ({ name, region, country, vintage, description }) => (
  <Wine>
    <Headings className="Body2-Bold">
      <Title>
        {name} {getVintage(vintage)}
      </Title>
      <Origin>
        {region}, {country}
      </Origin>
    </Headings>
    <Description className="Body2">{description}</Description>
  </Wine>
);
