import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { color, device } from '../../../constants';

const formatDate = date => dayjs(date).format('MMMM DD, YYYY');

const ReviewTitle = styled.div`
  background-color: ${color.cream2};
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    min-height: 184px;
  }

  @media ${device.laptop} {
    min-height: 200px;
    max-width: 1200px;

    padding-bottom: 80px;
  }
`;

const Content = styled.div`
  margin-top: 90px;
  margin-left: 20px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    margin-top: 100px;
    margin-bottom: 0px;
  }

  @media ${device.laptop} {
    margin-left: 215px;
  }
`;

const H2 = styled.h2`
  margin-bottom: 10px;
  font-size: 38px;

  @media ${device.tablet} {
    font-size: 48px;
  }

  @media ${device.laptop} {
    font-size: 92px;
  }
`;

const SubText = styled.p`
  font-size: 13px;
  margin-top: 5px;

  @media ${device.tablet} {
    font-size: 15px;
    margin-top: 0px;
  }
`;

const Span = styled.span`
  font-size: 13px;
  margin-top: 5px;

  @media ${device.tablet} {
    font-size: 15px;
    margin-top: 0px;
  }
`;

export default ({ title, author, date }) => (
  <ReviewTitle>
    <Content>
      <H2>{title}</H2>
      <SubText className="Body3-Bold">
        {author.name} <Span className="Body3">{formatDate(date)}</Span>
      </SubText>
    </Content>
  </ReviewTitle>
);
