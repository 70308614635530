import styled from '@emotion/styled';
import React from 'react';
import { color, device } from '../../../constants';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${color.white};

  width: 90%;

  top: -50px;

  @media ${device.tablet} {
    top: -120px;

    box-sizing: border-box;
    padding-top: 30px;
    flex-direction: row;

    justify-content: flex-start;
    align-items: flex-start;

    width: 557px;
    height: 243px;
  }

  @media ${device.laptopL} {
    top: -100px;

    width: 704px;
    height: 301px;
  }
`;

export const Rating = ({ rating }) => {
  const Rating = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin-left: 57px;
    margin-right: 27px;

    max-height: 150px;

    h2 {
      margin-top: 0px;
    }

    @media ${device.tablet} {
      margin-top: 55px;

      h2 {
        margin-bottom: 0px;
        margin-right: 5.5px;
      }
    }
  `;

  return (
    <Rating>
      <h2>{rating}</h2>
    </Rating>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 0px;
  margin-right: 20px;

  p {
    font-size: 12px;
  }

  button,
  button:focus,
  button:active {
    all: unset;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const Button = ({ onClick, expanded }) => (
  <ButtonContainer>
    <button onClick={onClick}>
      <p className="Body1">{expanded ? 'close' : 'more'}</p>
    </button>
  </ButtonContainer>
);

export const List = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  flex-direction: column;

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const ListItem = styled.span`
  border-bottom: 1px solid ${color.grey3};

  margin-left: 10px;
  margin-right: 10px;

  padding-top: 5px;
  padding-bottom: 5px;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  :last-of-type {
    border-bottom: 0px;

    padding-bottom: 20px;
  }

  @media ${device.tablet} {
    justify-content: flex-start;
    border-bottom: 0px;

    max-height: 38px;

    padding-top: 0px;
    padding-bottom: 0px;

    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media ${device.laptopL} {
    max-height: 50px;
  }
`;
