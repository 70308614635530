import React from 'react';
import MapBox from '../../Map';
import Location from './Location';

export default props => {
  const { latitude, longitude, token, style, streetAddress, title } = props;

  return (
    <MapBox
      latitude={latitude}
      longitude={longitude}
      zoom={16}
      token={token}
      style={style}
      interactive={false}
    >
      <Location streetAddress={streetAddress} title={title} />
    </MapBox>
  );
};
