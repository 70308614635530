import React from 'react';
import styled from '@emotion/styled';
import Food from './Food';
import { device, fontWeight } from '../../../constants';

const FoodList = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 100%;

  @media ${device.tablet} {
    margin-left: 0px;
  }
`;

const Headline = styled.p`
  min-width: 307px;
  margin-left: 30px;
  margin-top: 48px;

  font-size: 24px;
  font-weight: ${fontWeight.regular};

  @media ${device.tablet} {
    font-size: 38px;
    margin-top: 54px;

    min-width: 663px;
  }

  @media ${device.laptopL} {
    margin-top: 62px;
  }
`;

const Slider = styled.div`
  display: flex;
  overflow-x: auto;

  max-width: 100%;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @media ${device.tablet} {
    flex-direction: row;
    overflow-x: visible;
  }
`;

export default ({ foods }) => (
  <FoodList>
    <Headline className="SubHeading2">Food</Headline>
    <Slider>
      {foods.map(({ image, ...food }) => (
        <Food image={image.file.url} {...food} />
      ))}
    </Slider>
  </FoodList>
);
