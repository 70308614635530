import React from 'react';
import styled from '@emotion/styled';
import { BLOCKS } from '@contentful/rich-text-types';
import LazyLoad from 'react-lazyload';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { localisation, device, color } from '../../../constants';

const ReviewBody = styled.div`
  white-space: pre-wrap;
`;

const Headline = styled.p`
  max-width: 315px;
  font-size: 24px;

  margin-top: 20px;
  margin-bottom: 22px;

  @media ${device.mobileM} {
    max-width: 335px;
  }

  @media ${device.tablet} {
    margin-right: auto;
    max-width: 500px;

    margin-top: 0px;
    margin-bottom: 39px;

    font-size: 28px;
    text-align: left;
  }

  @media ${device.laptopL} {
    margin-bottom: 24px;

    font-size: 38px;
    max-width: 600px;
  }
`;

const Body = styled.p`
  max-width: 315px;

  margin-top: 10px;

  font-size: 18px;

  @media ${device.mobileM} {
    max-width: 335px;
  }

  @media ${device.tablet} {
    max-width: 661px;
  }

  a,
  a:link,
  a:visited {
    color: ${color.grey8};
  }
`;

const Image = styled.img`
  max-width: 315px;

  @media ${device.mobileM} {
    max-width: 335px;
  }

  @media ${device.tablet} {
    max-width: 661px;
  }
`;

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { fields } = node.data.target;
      const title = fields.title[localisation];
      const imageURL = fields.file[localisation].url;

      return (
        <LazyLoad height={'100%'}>
          <Image title={title} src={imageURL} />
        </LazyLoad>
      );
    },
  },
};

export default ({ bodyRichText, headline }) => (
  <ReviewBody>
    <Headline className="SubHeading2">{headline}</Headline>
    <Body className="Body1">
      {documentToReactComponents(bodyRichText, options)}
    </Body>
  </ReviewBody>
);
