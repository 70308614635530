import React from 'react';
import styled from '@emotion/styled';
import { device, color } from '../../../constants';
import Wine from './Wine';

const WineList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Headline = styled.p`
  margin-top: 64px;
  min-width: 307px;

  font-size: 24px;

  @media ${device.tablet} {
    min-width: 462px;
    font-size: 38px;

    margin-top: 56px;
    margin-bottom: 37px;
  }

  @media ${device.laptopL} {
    margin-top: 29px;
    min-width: 663px;
    margin-bottom: 24px;
  }
`;

const Break = styled.hr`
  height: 1px;

  border-width: 0;
  color: ${color.grey5};
  background-color: ${color.grey5};
  width: 100%;
`;

export default ({ wines }) => (
  <WineList>
    <Headline className="SubHeading2">Wine</Headline>
    {wines.map(wine => (
      <>
        <Wine {...wine} />
        <Break />
      </>
    ))}
  </WineList>
);
