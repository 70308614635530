import React from 'react';
import styled from '@emotion/styled';
import { device } from '../../../constants';

const Food = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 335px;

  :first-of-type {
    margin-left: 30px;
  }

  :nth-of-type(n + 2) {
    margin-left: 10px;
    padding-right: 10px;
  }

  @media ${device.tablet} {
    max-width: 327px;

    :nth-of-type(n + 2) {
      margin-left: 20px;
    }
  }

  @media ${device.laptopL} {
    max-width: 492px;
  }
`;

const Image = styled.img`
  max-width: 247px;
  max-height: 189px;

  @media ${device.tablet} {
    max-width: 327px;
    max-height: 251px;
  }

  @media ${device.laptopL} {
    width: 492px;
    height: 377px;
    max-width: 492px;
    max-height: 377px;
  }
`;

const Headings = styled.p`
  margin-top: 15px;
  font-size: 15px;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

const Description = styled.p`
  font-size: 13px;

  white-space: pre-wrap;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export default ({ name, image, description }) => (
  <Food>
    <Image src={image} alt="Food" />
    <Headings className="SubHeading3">{name}</Headings>
    <Description className="Body2">{description}</Description>
  </Food>
);
