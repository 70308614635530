import React from 'react';
import styled from '@emotion/styled';
import SpeechBubble from './SpeechBubble';
import dropPin from '../../../assets/dropPinBordered.svg';
import { device } from '../../../constants';

const Location = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  height: 100%;

  .DropPin {
    width: 40px;
    height: 40px;

    @media ${device.laptopL} {
      width: 50px;
      height: 50px;
    }
  }
`;

export default props => (
  <Location>
    <SpeechBubble {...props} />
    <img className="DropPin" src={dropPin} alt="DropPin" />
  </Location>
);
